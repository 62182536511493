import React from 'react';
import CloseIcon from "@material-ui/icons/Close"

import './CloseIcon.scss';

const _CloseIcon = ({ onClose }) => (
  <div className="close-icon" onClick={(e) => {
    e.stopPropagation();
    onClose();
  }}>
    <CloseIcon />
  </div>
);

export default _CloseIcon;
