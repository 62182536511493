import React from "react"
import Modal from "@material-ui/core/Modal"

import "./Modal.scss"

const CustomModal = ({ isOpen, setOpen, children, className = "" }) => {
  return (
    <Modal
      className={className}
      open={isOpen}
      onBackdropClick={e => e.stopPropagation()}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="modal-body">
        {children}
      </div>
    </Modal>
  )
}

export default CustomModal
